// Here you can add other styles

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--cui-toast-padding-y) var(--cui-toast-padding-x);
  color: var(--cui-toast-header-color);
  background: none;
  background-clip: padding-box;
  border-bottom: 1px solid #bbb;
}

.cursor-pointer {
  cursor: pointer;
}

.card-header {
  padding: var(--#{$prefix}card-cap-padding-y) var(--#{$prefix}card-cap-padding-x);
  margin-bottom: 0; // Removes the default margin-bottom of <hN>
  color: var(--#{$prefix}card-cap-color);
  background-color: white !important;
  border-bottom: none !important;

  &:first-child {
    @include border-radius(var(--#{$prefix}card-inner-border-radius) var(--#{$prefix}card-inner-border-radius) 0 0);
  }
}

.text-small {
  //font-family: "Lucida Console", "Lucida Sans Typewriter", monaco, "Bitstream Vera Sans Mono", monospace;
  font-size: 13px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 18.5714px;
}

.text-bold {
  font-weight: bold !important;
}

.button-option:hover {
  color: white !important
}

.notification-dropdown {
  min-width: 25rem !important;
}

.notification-icon {
  width: 1.7rem !important;
  height: 1.7rem !important;
}

#xStoreCodeColumn:disabled {
  text-align: center;
  background-color: white;
  border-color: white;
  opacity: 1
}


// html:not([dir=rtl]) .form-check .form-check-input {
//   float: right;
// }

html:not([dir=rtl]) .dynamic-form-check-input .form-check .form-check-input {
  float: right;
  border: 1px solid red;
}

.form-check-reverse .form-check-input {
  float: right;
  margin-right: 0;
  margin-left: 0;
}