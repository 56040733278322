.main-content {
  color: black !important;
  font-weight: bold;

  table{
    color: black !important;
    border-color: black;
    font-weight: bold;
    width: 96%;
    font-size: 11px !important;

    td {
      color: black !important;
      font-size: 9px !important;
      font-weight: bold;
      font-style: normal;
      font-variant: normal;
      font-weight: 400;
      line-height: 18.5714px;
    }
    
    th {
      color: black !important;
      font-size: 10px !important;
      font-weight: bold;
      font-style: normal;
      font-variant: normal;
      font-weight: 400;
      line-height: 18.5714px;
    }
  }

  .table > :not(caption) > * > * {
    font-weight: bold;
    padding: 0;
    background-color: var(--cui-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--cui-table-accent-bg);
  }
}

@media print {
  @page {
    size: 85mm 150mm;
  }
}

@media all {
  .page-break {
      display: none;
    }
}

@media print {
  html,
  body {
    color:black;
  }
}

@media print {
  .page-break {
      margin-top: 1rem;
      display: block;
      page-break-before: auto;
    }
}