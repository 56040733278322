.voucher-content {
  color: black !important;
  line-height: normal;
  -webkit-print-color-adjust:exact !important;
  print-color-adjust: exact !important;
  font-family: "Cascadia Code", "Lucida Console", "Lucida Sans Typewriter", monaco, "Bitstream Vera Sans Mono", monospace;

  img {
    -webkit-filter: grayscale(100%) !important; /* Safari 6.0 - 9.0 */
    filter: grayscale(150%) !important;
  }
}

@media print {
  @page {
    size: 85mm 150mm;
  }
}

@media all {
  .page-break {
      display: none;
    }
}

@media print {
  html,
  body {
    color:black;
    -webkit-print-color-adjust:exact !important;
    print-color-adjust:exact !important;
    line-height: normal;
    font-family: "Cascadia Code", "Lucida Console", "Lucida Sans Typewriter", monaco, "Bitstream Vera Sans Mono", monospace;

    img {
      -webkit-filter: grayscale(100%) !important; /* Safari 6.0 - 9.0 */
      filter: grayscale(150%) !important;
    }
  }

  img {
    -webkit-filter: grayscale(100%) !important; /* Safari 6.0 - 9.0 */
    filter: grayscale(150%) !important;
  }
}

@media print {
  .page-break {
      margin-top: 1rem;
      display: block;
      page-break-before: auto;
  }

  img {
    -webkit-filter: grayscale(100%) !important; /* Safari 6.0 - 9.0 */
    filter: grayscale(150%) !important;
  }
}