.endowment-report-content {
  margin-top: 10%;
  color: black !important;

  table{
    color: black !important;
    border-color: black;
    width: 100%;
    // font-size: 12px !important;

    td {
      color: black !important;
      // font-size: 6px !important;
      font-weight: bold;
      font-style: normal;
      font-variant: normal;
      font-weight: 400;
      line-height: 18.5714px;
    }
    
    th {
      color: black !important;
      // font-size: 6px !important;
      font-weight: bold;
      font-style: normal;
      font-variant: normal;
      font-weight: 400;
      line-height: 18.5714px;
    }
  }

  // .table > :not(caption) > * > * {
  //   // font-weight: bold;
  //   padding: 0;
  //   background-color: var(--cui-table-bg);
  //   border-bottom-width: 1px;
  //   box-shadow: inset 0 0 0 9999px var(--cui-table-accent-bg);
  // }
}

@media print {
  @page {
    // size: 85mm 150mm;
  }
}

@media all {
  .page-break {
      display: none;
    }
}

@media print {
  html,
  body {
    color:black;
    font-size: 1rem;
  }
}

@media print {
  .page-break {
      margin-top: 1rem;
      display: block;
      page-break-before: auto;
    }
}